<template>
  <div
    class="category__select-container"
    :class="{
      ['category__select-container--with-small-text']: withSmallText,
      [`category__select-container--${size}`]: true,
      [`category__select-container--keep-placeholder-displayed`]:
        size === SelectFieldSize.LARGE && multiple
    }"
  >
    <label
      v-if="!!topLabel"
      class="select-container__label"
    >
      {{ topLabel }}
      <span
        v-if="required"
        class="select-container__label--required"
      >
        *
      </span>
    </label>
    <Multiselect
      :disabled="disabled"
      :show-no-options="showNoOptions"
      :placeholder="placeholder"
      :track-by="trackBy"
      :searchable="searchable"
      :open-direction="openDirection"
      :max="max"
      :allow-empty="allowEmpty"
      :label="hasLabeledOptions ? 'label' : undefined"
      :multiple="multiple"
      :close-on-select="closeOnSelect"
      :model-value="value"
      :options="filteredOptions"
      :internal-search="false"
      :group-label="hasCategorizedOptions ? 'category' : undefined"
      :group-values="hasCategorizedOptions ? 'options' : undefined"
      :group-select="groupSelect"
      :hide-selected="hideSelected"
      :show-labels="showLabels"
      :max-height="maxHeight"
      :style="{ cursor }"
      @search-change="(value: string) => handleSearchChange(value)"
      @update:model-value="(value: string[] | string) => $emit('input', value)"
      @select="(value: string | any) => $emit('select', value)"
    >
      <template #option="props">
        <slot
          name="option"
          v-bind="props"
        />
      </template>
      <template #singleLabel="props">
        <slot
          name="singleLabel"
          v-bind="props"
        />
      </template>
      <template #maxElements>
        <slot name="maxElements" />
      </template>
      <template #noResult>
        <slot name="noResult" />
      </template>
      <template #noOptions>
        <slot name="noOptions" />
      </template>
      <template #caret>
        <slot name="caret" />
      </template>
    </Multiselect>
  </div>
</template>

<script lang="ts">
import type { SlotsType } from 'vue'
import { Multiselect } from 'vue-multiselect'
import { isIncludedIn } from '~/services/search'

type LabelledOption = { label: string }

type CategoryWithOptions = {
  category: string
  options: LabelledOption[]
}

type Options =
  | (string | number | null)[]
  | CategoryWithOptions[]
  | LabelledOption[]

type Option = Options[number]

export enum SelectFieldSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  FULL_WIDTH = 'full-width'
}

export default {
  name: 'SelectField',
  components: {
    Multiselect
  },
  props: {
    size: {
      type: String as PropType<SelectFieldSize>,
      default: SelectFieldSize.MEDIUM
    },
    withSmallText: {
      type: Boolean,
      default: false
    },
    allowEmpty: {
      type: Boolean,
      default: true
    },
    topLabel: {
      type: String,
      default: undefined
    },
    required: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    searchable: {
      type: Boolean,
      default: true
    },
    multiple: {
      type: Boolean,
      default: false
    },
    max: {
      type: Number,
      default: undefined
    },
    trackBy: {
      type: String,
      default: undefined
    },
    openDirection: {
      type: String,
      default: undefined
    },
    closeOnSelect: {
      type: Boolean,
      default: false
    },
    value: {
      type: [Object, Array, String, null] as PropType<
        Object | Array<any> | null | string
      >,
      default: () => {}
    },
    options: {
      type: Array as PropType<Options>,
      default: () => []
    },
    groupSelect: {
      type: Boolean,
      default: false
    },
    hideSelected: {
      type: Boolean,
      default: true
    },
    showLabels: {
      type: Boolean,
      default: false
    },
    maxHeight: {
      type: Number,
      default: 400
    },
    cursor: {
      type: String,
      default: 'pointer'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    showNoOptions: {
      type: Boolean,
      default: false
    },
    hasCategorizedOptions: {
      type: Boolean,
      default: false
    },
    hasLabeledOptions: {
      type: Boolean,
      default: false
    },
    isSearchValueAddedInOptions: {
      type: Boolean,
      default: false
    }
  },
  slots: Object as SlotsType<{
    option: { option: Option }
    singleLabel: { option: Option }
    maxElements: {}
    noResult: {}
    noOptions: {}
    caret: {}
  }>,
  emits: ['input', 'search-change', 'select'],
  setup() {
    return { SelectFieldSize }
  },
  data(): { filteredOptions: Options } {
    return { filteredOptions: [...this.options] as Options }
  },
  watch: {
    options: {
      handler() {
        this.filteredOptions = [...this.options] as Options
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    handleSearchChange(value: string) {
      this.emitSearchChangeEvent(value)
      this.filterOptions(value)
    },
    emitSearchChangeEvent(value: string) {
      this.$emit('search-change', value)
    },
    filterOptions(value: string) {
      if (this.hasCategorizedOptions) {
        this.filteredOptions = [
          ...(this.options as CategoryWithOptions[]).map(category => {
            const updatedNestedOptions = [
              ...category.options.filter(option => {
                return isIncludedIn(value, option.label)
              })
            ]
            return { ...category, options: updatedNestedOptions }
          })
        ]
      } else if (this.hasLabeledOptions) {
        this.filteredOptions = [
          ...(this.isSearchValueAddedInOptions
            ? [{ label: value, value } as LabelledOption]
            : []),
          ...(this.options as LabelledOption[]).filter(option =>
            isIncludedIn(value, option.label)
          )
        ]
      } else {
        this.filteredOptions = [
          ...(this.isSearchValueAddedInOptions ? [value] : []),
          ...(this.options as string[]).filter(option =>
            isIncludedIn(value, option)
          )
        ]
      }
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style lang="scss" scoped>
/* stylelint-disable no-descending-specificity */
.category {
  &__name {
    @include font-text;
    @include font-size($font-size-mini);
    color: $text-secondary;
    margin-bottom: $margin-tiny;
  }

  &__select-container {
    position: relative;
    width: 240px;
    cursor: pointer;
    max-width: 100%;

    :deep() {
      li {
        // The :not(i) selector is needed to be able to render icons in custom options
        :empty:not(i) {
          display: none;
        }
      }

      .multiselect__tags {
        background-color: $field-background;
        border: none;
        border-radius: 8px;
      }

      .multiselect__tag {
        @include font-text;
        @include font-size($field-font-size);
        color: $text-primary;
        background: transparent;
        padding: 2px 24px 0 0;
      }

      .multiselect__placeholder {
        @include font-text($font-weight-regular);
        @include font-size($field-font-size);
        color: $text-tertiary;
      }

      .multiselect__single {
        background: transparent;
        @include font-text($field-font-weight);
        @include font-size($field-font-size);
        color: $text-primary;
        padding: 2px 0 0;
        overflow-x: hidden;
        text-overflow: ellipsis;
      }

      .multiselect__select {
        background: transparent;
      }

      .multiselect__tag-icon {
        border: none;
        border-radius: 50%;
        background-color: $link-secondary;
        opacity: 0.3;
        margin-top: 4px;
        height: 18px;
        width: 18px;
        line-height: 18px;
      }

      .multiselect__input {
        background: transparent;
        @include font-text($font-weight-regular);
        @include font-size($field-font-size);
        color: $text-tertiary;
        padding: 2px 0 0;
      }

      .multiselect__option {
        @include font-text;
        @include font-size($font-size-regular);
        color: $text-primary;
        padding: 8px 8px 6px;
        min-height: 36px;
      }

      .multiselect__content-wrapper {
        background: $bg-primary;
        border: none;
        border-radius: 8px;
      }

      .multiselect__option--group {
        @include font-size($font-size-mini);
        background: $bg-tertiary !important;
        color: $text-inverse !important;
        cursor: default;
        text-align: center;
        min-height: 20px;
        height: 20px;
        padding: 1px 0;
      }

      .multiselect__option--highlight {
        background: $button-primary;
        color: $text-inverse;
      }

      .multiselect--disabled {
        background: $bg-primary;
        border: none;
        border-radius: 8px;
      }

      .multiselect__tag-icon:hover {
        opacity: 0.4;
        background-color: $color-error;
      }

      .multiselect__input::placeholder {
        @include font-text($font-weight-regular);
        @include font-size($field-font-size);
        color: $text-tertiary;
      }

      .multiselect__tag-icon::after {
        color: $text-inverse;
        font-size: 16px;
      }

      .multiselect__select::before {
        border-color: $text-tertiary transparent transparent;
      }
    }

    .select-container {
      &__label {
        display: block;
        @include font-text;
        @include font-size($font-size-mini);
        color: $text-primary;
        margin-bottom: $margin-tiny;
        text-align: left;

        &--required {
          color: $color-error;
        }
      }
    }

    &--with-small-text {
      :deep() {
        .multiselect {
          min-height: unset;

          &__tags {
            min-height: 32px;
            padding: 0 40px 0 8px;
          }

          &__select {
            height: 32px;
          }

          &__tags-wrap {
            display: flex;
            flex-wrap: wrap;
          }

          &__tag {
            @include font-size($font-size-mini);
            padding: 0 24px 0 0;
            margin-bottom: unset;
            line-height: 32px;
          }

          &__tag-icon {
            height: 14px;
            width: 14px;
            line-height: 14px;
            top: 50%;
            transform: translateY(-50%);
            margin-top: unset;
          }

          &__placeholder {
            @include font-size($font-size-mini);
            line-height: 32px;
            margin: unset;
            padding: unset;
          }

          &__single {
            @include font-size($font-size-mini);
            padding: unset;
            margin-bottom: unset;
            line-height: 32px;
          }

          &__input {
            @include font-size($font-size-mini);
            padding: 2px 0 0;
            height: 32px;
            margin: unset;
          }

          &__input::placeholder {
            @include font-size($font-size-mini);
          }

          &__option {
            @include font-size($font-size-mini);
            padding: 8px 8px 6px;
            min-height: 36px;

            &--group {
              @include font-size($font-size-mini);
              min-height: 20px;
              height: 20px;
              padding: 1px 0;
            }
          }
        }
      }
    }

    &--small {
      width: 200px;
    }

    &--medium {
      width: 240px;
    }

    &--large {
      width: 452px;
    }

    &--full-width {
      width: 100%;
    }

    &--keep-placeholder-displayed {
      :deep(.multiselect__input) {
        width: 50% !important;
        padding: 2px 0 !important;
      }
    }
  }
}
/* stylelint-enable no-descending-specificity */
</style>
